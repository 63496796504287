var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column flex-root" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30",
        style: {
          backgroundImage: `url(${_vm.backgroundImage})`,
        },
      },
      [
        _c(
          "h1",
          {
            staticClass:
              "font-size-sm-100 font-weight-boldest text-dark-75 mt-15",
            staticStyle: { "font-size": "150px" },
          },
          [_vm._v(" 404 ")]
        ),
        _c("p", { staticClass: "font-size-h3 font-weight-light" }, [
          _vm._v(" OOPS! Something went wrong here "),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }